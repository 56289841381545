import { Component } from '@angular/core';
import { OauthService } from '../../_services/oauth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  constructor(
    private oauth: OauthService
  ) {
  }

  login() {
    this.oauth.codeFlow('graph').then();
  }
}
