import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[DesTemplate]'
})
export class DesTemplateDirective {
  @Input('DesTemplate') type: string | undefined;

  constructor(public template: TemplateRef<unknown>) {}

  getType(): string {
    return this.type ?? '';
  }

}
