import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { OauthService } from '../_services/oauth.service';
import { inject } from '@angular/core';
import { TokenCodeResponse } from '../models/token.model';
import { JwtHelperService } from '@auth0/angular-jwt';

export const authGuard: CanActivateFn = (route, state): Promise<boolean| UrlTree> => {
  const data: {[k: string]: string} = {};

  route.fragment?.split('&').forEach(part => {
    const [key, value] = part.split('=');
    data[key] = value;
  });

  const oauth = inject(OauthService) ;
  const router = inject(Router);


  return new Promise<boolean | UrlTree>(resolve => {
    oauth.requestToken(data as unknown as TokenCodeResponse).then(() => {
      if (!localStorage.getItem('graph-token') && !localStorage.getItem('requested-token')) {
        oauth.codeFlow('graph').then();
      }
      if (!localStorage.getItem('backend-token') && !localStorage.getItem('requested-token')) {
        oauth.codeFlow('backend').then(() => router.navigate(['/home']));
      }
      resolve(router.createUrlTree(['home']));
    }, () => {
      resolve(router.createUrlTree(['login']));
    });
  })
};

export const authGuardContent: CanActivateFn = (route, state): Promise<boolean | UrlTree> => {
  const router = inject(Router);
  const jwtService = inject(JwtHelperService);

  return new Promise<boolean | UrlTree>( async resolve=> {
    let exp;
    try {
      exp = await jwtService.isTokenExpired();
    } catch {
      exp = true;
    }
    if (!exp) {
      resolve(true)
    }
    resolve(router.createUrlTree(['login']));
  })
};

export const authGuardLogin: CanActivateFn = (route, state): Promise<boolean | UrlTree> => {
  const jwtService = inject(JwtHelperService);
  const router = inject(Router);

  return new Promise( async resolve=> {
    let exp;
    try {
      exp = await jwtService.isTokenExpired();
    } catch {
      exp = true;
    }
    if (exp) {
      resolve(true);
    }
    resolve(router.createUrlTree(['home']));
  })
};
