<div class="loading-outer">
  <app-card>
    <div class="loading-inner">
      <div>
        <img style="height: 2rem" src="assets/DES-Logo-Techmoss.png">
      </div>
      <div class="circle-container">
        <div *ngFor="let n of [0,1,2,3,4,5]" class="circle"></div>
      </div>
      <div><span class="loading-text">LÄDT</span></div>
    </div>
  </app-card>
</div>
