import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenCodeResponse, TokenConfig, TokenResponse } from '../models/token.model';

@Injectable({
  providedIn: 'root'
})
export class OauthService {

  configs: {[k: string]: TokenConfig } = {
    graph: {
      clientId: 'a287341c-c04e-46a0-a6e1-15e32c530f89',
      scope: 'openid User.Read',
      responseType:'code id_token',
      redirectUri: environment.frontendURL + '/auth',
      responseMode: 'fragment',
      codeChallenge_method: 'S256',
    },
    backend: {
      clientId: 'a287341c-c04e-46a0-a6e1-15e32c530f89',
      scope: 'https://projekt-planer.des.de/api/*',
      responseType: 'code',
      redirectUri: environment.frontendURL + '/auth',
      responseMode: 'fragment',
      codeChallenge_method: 'S256'
    }
  }

  constructor(
    private httpClient: HttpClient
  ) { }

  public async codeFlow(tokenName: string) {
    const token = this.configs[tokenName]

    let nonce = '';
    if (token.responseType.includes('id_token')) {
      nonce = OauthService.generateRandomString(10);
      localStorage.setItem(tokenName + '-nonce', nonce);
    }
    const state = OauthService.generateRandomString(10);
    localStorage.setItem(tokenName + '-state', state);
    OauthService.createChallengeVerifierPairForPKCE('SHA-256').then(challenge => {
      const tokenURL = OauthService.getTokenRequestUrl(
        'https://login.microsoftonline.com/2364d4e6-fd62-43ae-901d-ef9844ed1b22/oauth2/v2.0/authorize', token, state, nonce, challenge[0], token.codeChallenge_method
      );
      localStorage.setItem('requested-token', tokenName);
      localStorage.setItem(tokenName + '-verifier', challenge[1]);
      location.href = tokenURL;
    });
  }

  public requestToken(code: TokenCodeResponse): Promise<void> {
    return new Promise((resolve, reject) => {
      const tokenName = localStorage.getItem('requested-token') ?? '';
      if (!tokenName) {
        reject();
        return;
      }
      const token = this.configs[tokenName];
      const redirectAddress = encodeURIComponent(token.redirectUri);
      const tokenHeader = {headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'})};
      const oauthTokenGetter = this.getTokenGetterUrl(token, code.code, redirectAddress, tokenName);
      this.httpClient.post<TokenResponse>('https://login.microsoftonline.com/2364d4e6-fd62-43ae-901d-ef9844ed1b22/oauth2/v2.0/token', oauthTokenGetter, tokenHeader).subscribe({
        next: res => {
          res.access_token && localStorage.setItem(tokenName + '-token', res.access_token);
          res.id_token && localStorage.setItem(tokenName + '-idtoken', res.id_token);
          res.refresh_token && localStorage.setItem(tokenName + '-refresh', res.refresh_token);
          localStorage.removeItem('requested-token');
          resolve();
        }, error: () => {
          reject();
        }
      });
    });
  }

  public refresh(name: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const token = this.configs[name];
      if (!localStorage.getItem(name + '-refresh')) {
        localStorage.removeItem(name + '-token');
        reject()
        return
      }

      const tokenHeader = {headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'})};
      const oauthTokenURL = this.getRefreshUrl(token, name);
      this.httpClient.post<TokenResponse>('https://login.microsoftonline.com/2364d4e6-fd62-43ae-901d-ef9844ed1b22/oauth2/v2.0/token', oauthTokenURL, tokenHeader).subscribe({next: res =>
        {
          res.access_token && localStorage.setItem(name + '-token', res.access_token);
          res.id_token && localStorage.setItem(name + '-idtoken', res.id_token);
          res.refresh_token && localStorage.setItem(name + '-refresh', res.refresh_token);
          resolve();
        }, error: () => {
          localStorage.removeItem(name + '-token');
          localStorage.removeItem(name + '-idtoken');
          reject();
        }
      })
    });
  }

  // ------------ SOME HELPER FUNCTIONS --------------------------------

  private getTokenRefresh(name: string): string {
    return localStorage.getItem(name + '-refresh') ?? '';
  }

  private setTokenRefresh(name: string, value: string) {
    localStorage.setItem(name + '-refresh', value);
  }

  private static async createChallengeVerifierPairForPKCE(method: string): Promise<[string, string]> {
    const verifier = OauthService.createVerifier();
    const challenge =  OauthService.b64EncodeURLnoPadding(
      String.fromCharCode(...(new Uint8Array(await window.crypto.subtle.digest(method, (new TextEncoder()).encode(verifier)))))
    );
    return [challenge, verifier];
  }

  private static b64EncodeURLnoPadding(str: any): string {
    return btoa(str)
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, '');
  }

  protected static createVerifier(): string {
    const size = 45;
    return OauthService.b64EncodeURLnoPadding(OauthService.generateRandomString(size));
  }

  private static getTokenRequestUrl(
    url: string,
    token: TokenConfig,
    state: string,
    nonce: string,
    challenge: string,
    challengeMethod: string
  ): string {
    return `${url}?
        ${OauthService.getUrlBase(token)}
        &response_type=${encodeURIComponent(token.responseType)}
        &redirect_uri=${encodeURIComponent(token.redirectUri)}
        &state=${state}
        &response_mode=${encodeURIComponent(token.responseMode)}
        ${ nonce && '&nonce=' + nonce}
        &code_challenge=${encodeURIComponent(challenge)}
        &code_challenge_method=${encodeURIComponent(challengeMethod)}`;
  }

  private getTokenGetterUrl(token: TokenConfig, code: string, redirectAddress: string, tokenName: string): string {
    return OauthService.getUrlBase(token) +
      `&code=${code}
      &redirect_uri=${redirectAddress}
      &grant_type=authorization_code
      &code_verifier=${localStorage.getItem(tokenName + '-verifier')}`
  }

  private getRefreshUrl(token: TokenConfig, tokenName: string): string {
    return OauthService.getUrlBase(token) +
      `&refresh_token=${this.getTokenRefresh(tokenName)}
      &grant_type=refresh_token`;
  }

  private static getUrlBase(token: TokenConfig): String {
    return `client_id=${encodeURIComponent(token.clientId)}
      &scope=${encodeURIComponent(token.scope)}`
  }

  private static generateRandomString(length: number): string {
    const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let result             = '';
    for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

}
