import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './_template/login/login.component';
import { LoadingComponent } from './_template/loading/loading.component';
import { authGuard, authGuardContent, authGuardLogin } from './_guards/auth.guard';

const routes: Routes = [
  {path: 'login', canActivate: [authGuardLogin], component: LoginComponent},
  {path: 'auth', canActivate: [authGuard], component: LoadingComponent},
  {path: 'home', canActivate: [authGuardContent], loadChildren: () => import('./_modules/home/home.module').then(m => m.HomeModule)},
  {path: '', pathMatch: 'full', redirectTo: 'login'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
