import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { map, Observable } from 'rxjs';

@Pipe({
  name: 'imageAuth'
})
export class ImageAuthPipe implements PipeTransform {

  constructor(private httpClient: HttpClient, private sanitizer: DomSanitizer) { }

  transform(url: string): Observable<SafeUrl> {
    return this.httpClient
      .get(url, { responseType: 'blob' }).pipe(
        map(val  => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val))));
  }

}
