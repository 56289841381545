import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DesTemplateDirective } from './_directives/des-template.directive';
import { CardComponent } from './_template/card/card.component';
import { ImageAuthPipe } from './_pipe/image-auth.pipe';



@NgModule({
    declarations: [
        DesTemplateDirective,
        CardComponent,
        ImageAuthPipe
    ],
    exports: [
        CardComponent,
        ImageAuthPipe
    ],
    imports: [
        CommonModule
    ]
})
export class SharedModule { }
