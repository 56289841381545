import { inject } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { OauthService } from './_services/oauth.service';
import { HttpRequest } from '@angular/common/http';

export const TokenGetter = (httpRequest?: HttpRequest<any>): string | Promise<string | null> | null => {
  let tokenName = 'backend';
  if (httpRequest && httpRequest.url.includes('graph.microsoft.com')) {
    tokenName = 'graph'
  }

  const jwtService = inject(JwtHelperService);
  const backendToken = localStorage.getItem(tokenName + '-token');
  if (backendToken && !jwtService.isTokenExpired(backendToken)) return backendToken;
  const oauth = inject(OauthService);

  return new Promise<string | null>(resolve => {
    oauth.refresh(tokenName).then(() => {
      resolve(localStorage.getItem(tokenName + '-token'))
    }, () => {
      resolve(null);
    });
  });

}
